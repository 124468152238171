<style>


    body {

        color: white;
        margin: 0;
        padding: 0;

    }

    .content {
        font-size: 14px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
        height: calc(100vh - 50px);
        overflow-y: auto;
        background: url("../../assets/answer_b.png") no-repeat center/cover fixed, linear-gradient(to top, #3BC0E1, #265599, #265599);
    }

    .line {
        display: flex;
    }

    .top1 {
        font-weight: bold;
        font-size: 20px;
        color: #FFFFFF;
        justify-content: center;
        padding: 15px 0;
        width: 100%;
    }

    .top2 {
        justify-content: space-between;
        color: rgba(255, 255, 255, 0.65);
        font-size: 12px;
        width: 100%;
    }

    .top2-1 {
        color: white;
        font-size: 14px
    }

    .proLine {
        margin-top: 10px;
    }

    .con {
        background: white;
        color: #333333;
        border-radius: 6px;
        margin-top: 20px;
        padding: 20px 15px;
        font-size: 12px;
        width: 100%;
    }

    .con-type {
        background-color: #265599;
        color: white;
        width: 56px;
        padding: 5px 10px;
        border-radius: 3px;
    }

    .con-con {
        color: #333333;
        margin-top: 15px;
        font-size: 14px;
    }

    .con-opt {
        font-size: 14px;
    }

    .el-radio {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        display: flex;
        background-color: #F9F9F9;
        align-items: center;
        margin-top: 10px;
        padding: 14px 16px;
        width: 100%;
        overflow-wrap: break-word;
        border-radius: 6px;
    }

    .is-checked {
        border-radius: 6px;
        background-color: #EFF7FF;

    }

    .el-radio__label {
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 18px;
        vertical-align: middle;
        display: inline-block;

    }

    /* 选中后的字体颜色 */
    .el-radio__input.is-checked + .el-radio__label {
        color: #265599 !important;
    }

    /* 选中后小圆点的颜色 */
    .el-radio__input.is-checked .el-radio__inner {
        background: #265599 !important;
        border-color: #265599 !important;
    }

    .el-checkbox {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        display: flex;
        background-color: #F9F9F9;
        align-items: center;
        margin-top: 10px;
        padding: 14px 16px;
        width: 100%;
        overflow-wrap: break-word;
        border-radius: 6px;

    }

    .is-checked {
        border-radius: 6px;
        background-color: #EFF7FF;

    }

    .el-checkbox__label {
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 18px;
        vertical-align: middle;
        display: inline-block;

    }


    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        border-color: #516aab !important;
        background-color: #516aab !important;
    }

    .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #516aab !important;
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #516aab !important;
    }


    .next {
        background: white;
        border-radius: 50px;
        color: #333333;
        padding: 12px 0;
        text-align: center;
        margin-top: 20px;
        width: 240px;
    }

    /* 当前状态颜色 */
    .progressBar >>> .el-progress-bar__outer {
        background-color: rgba(255, 255, 255, 0.2);
    }

    /* 进度条的背景色 */
    .progressBar >>> .el-progress-bar__inner {
        background-color: #fff;
    }


</style>
<template>

    <div class="content" v-if="show" style="">
        <div class="top1 line">{{attile}}</div>
        <div class="con">
            <div class="con-type" v-if="examData[index].type==1">
                单选题
            </div>
            <div class="con-type" v-if="examData[index].type==2">
                多选题
            </div>
            <div class="con-con">
                {{examData[index].title}}
            </div>
            <div class="con-opt" v-if="examData[index].type==1">
                <el-radio v-model="radio" label="A" v-if="examData[index].optionA">A、{{examData[index].optionA}}
                </el-radio>
                <el-radio v-model="radio" label="B" v-if="examData[index].optionB">B、{{examData[index].optionB}}
                </el-radio>
                <el-radio v-model="radio" label="C" v-if="examData[index].optionC">C、{{examData[index].optionC}}
                </el-radio>
                <el-radio v-model="radio" label="D" v-if="examData[index].optionD">D、{{examData[index].optionD}}
                </el-radio>
            </div>
            <div class="con-opt" v-if="examData[index].type==2">
                <el-checkbox-group v-model="checkList">
                    <el-checkbox label="A" v-if="examData[index].optionA">A、{{examData[index].optionA}}
                    </el-checkbox>
                    <el-checkbox label="B" v-if="examData[index].optionB">B、{{examData[index].optionB}}
                    </el-checkbox>
                    <el-checkbox label="C" v-if="examData[index].optionC">C、{{examData[index].optionC}}
                    </el-checkbox>
                    <el-checkbox label="D" v-if="examData[index].optionD">D、{{examData[index].optionD}}
                    </el-checkbox>
                </el-checkbox-group>
            </div>

        </div>

        <div class="next" @click="nextQuestion">下一题</div>
        <div class="next" style="margin-bottom: 20px" v-if="examData[index].answer"
             @click="showAF(examData[index].answer)">{{showA}}
        </div>

        <div @click="goHome" style="position: absolute;bottom: 0;left: 0;display: flex;align-items: center;justify-content: center;width: 100%;
        background-color: white;height: 50px;font-size: 14px;font-weight: bold;color: #333333">
            返回主页
        </div>
    </div>
</template>
<script>
    import request from "../../plugins/axios.js";

    export default {
        data() {
            return {
                showA: '查看答案',
                radio: "",
                examData: [{title: ''}],
                index: 0,
                pro: 0,
                checkList: [],
                show: false,
                wxShow: false,
                attile: '密码知识答题',
            };
        },
        components: {},
        mounted() {
            document.title = "密码知识题库"
            let that = this
            //设置背景动画播放速度
            let u = localStorage.getItem('u')
            if (!u) {
                this.$router.push("/login");
            }

            var userAgent = navigator.userAgent.toLowerCase();
            // 判断是否在微信中打开
            if (userAgent.indexOf('micromessenger') !== -1) {
                that.wxShow = true;
            } else {
                that.$message.error('请在微信中打开');
                that.wxShow = false;
                return false
            }

            let attile = '密码知识答题';
            let examtg = localStorage.getItem('examtg')
            if (examtg == 1) {
                attile = '政策法规';
            } else if (examtg == 2) {
                attile = '密码技术';
            } else if (examtg == 3) {
                attile = '密码产品';
            } else if (examtg == 4) {
                attile = '密码评估';
            }
            that.attile = attile

            that.exam()


        },
        methods: {
            showAF(a) {
                this.showA = a
            },
            goHome() {
                this.$router.push("/exam");
            },
            exam() {
                let that = this
                let u = localStorage.getItem('u')
                let group = localStorage.getItem('examtg')
                that.showA = "查看答案"
                /* let loadding = that.$loading({
                     lock: true,
                     text: '读题中...',
                     spinner: 'el-icon-loading',
                     background: 'rgba(0, 0, 0, 0.7)'
                 });*/
                that.radio = ""
                that.checkList = []
                request({
                    url: "/mn/e/qtest",
                    data: {
                        group: group,
                        error: localStorage.getItem('error')
                    },
                }).then((ret) => {

                    if (ret.code == 200) {
                        that.examData = ret.data
                        that.show = true
                    } else if (ret.code == 202) {
                        that.$message.error(ret.message);
                        this.$router.push("/exam");
                    } else if (ret.code == 204) {
                        location.href = '/login'
                    }
                    // loadding.close()
                })
            },
            nextQuestion() {
                let that = this
                let examData = that.examData
                let type = examData[0].type
                let detail = examData[0]
                let user_question_answer = '';
                if (type == 1) {
                    if (!that.radio) {
                        that.$message.error('请先选择答案');
                        return false
                    }
                    user_question_answer = that.radio
                }

                if (type == 2) {
                    if (that.checkList.length < 1) {
                        that.$message.error('请先选择答案');
                        return false
                    }
                    user_question_answer = that.checkList
                }
                let loadding = that.$loading({
                    lock: true,
                    text: '提交中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                //
                request({
                    url: "/mn/e/qtanswer",
                    data: {
                        qid: detail.id,
                        user_answer: JSON.stringify(user_question_answer),
                        exam_id: detail.exam_id,
                        group: localStorage.getItem('examtg'),
                        answer_id: detail.answer_id
                    },
                }).then((ret) => {

                    if (ret.code == 200) {
                        that.exam();
                    } else if (ret.code == 204) {
                        location.href = '/login'
                    } else {
                        that.$message.error(ret.message);
                    }
                    loadding.close()
                })

            }
        },
    };
</script>
